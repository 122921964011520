// @flow
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

import { appClient } from '_client';
import { GqlError } from '_utils/errors';

const GET_PRODUCTS = gql`
  query getProducts {
    products {
      id
      active
      description
      metadata
      name
      unitLabel
      prices {
        id
        active
        currency
        metadata
        recurring {
          interval
          intervalCount
          trialPeriodDays
          usageType
        }
        type
        unitAmount
        unitAmountDecimal
      }
    }
  }
`;

export const getProducts = async (): Promise<$ReadOnlyArray<Object>> => {
  try {
    const { products } = await appClient.request(GET_PRODUCTS);
    return products.filter(({ active }) => active === true);
  } catch (err) {
    throw new GqlError(err);
  }
};

const defaultConfig = {
  staleTime: Infinity,
  cacheTime: Infinity,
  retry: 0,
  refetchOnWindowFocus: false,
};

const useProducts = (config) => {
  const { data, isLoading } = useQuery('products', getProducts, {
    ...defaultConfig,
    ...config,
  });
  return { products: data, loading: isLoading };
};

export default useProducts;
